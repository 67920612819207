<template>
	<div class="subjective_view">
		<div class="interMant_top">
			<div class="interTop_left">
				<el-avatar :size="50">
					<img :src="infoData.head_img ? 'http://' + infoData.head_img : circleUrl" />
				</el-avatar>
				<div class="otim">
					姓名:
					<span>{{ infoData.name }}</span>
					性别:
					<span>{{ infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : '' }}</span>
					身高:
					<span>{{ infoData.height }}cm</span>
					体重:
					<span>{{ infoData.weight }}kg</span>
					项目:
					<span>{{ infoData.sport_name }}</span>
					运动队:
					<span>{{ infoData.department_name }}</span>
				</div>
			</div>
			<div class="interTop_right">
				<el-date-picker
					v-model="value1"
					@change="dateChange"
					:clearable="false"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
					:picker-options="customPickerOptions"
					:unlink-panels="true"
				>
				</el-date-picker>
				<span @click="reBack">
					<i class="iconfont iconfanhui"></i>
					返回
				</span>
			</div>
		</div>

		<div class="subjective_content" v-loading="Loading">
			<div class="content_top">
				<div class="content_top_left">
					<p class="morn">晨脉 (次/min)<el-input v-model="from.morning" @blur="statusChange(from.morning, 1)" @input="valueChange('morning')" :disabled="edit"></el-input></p>
					<div id="echartsFirst" class="echarts"></div>
					<div style="position: absolute; top: 50%; left: 47%" v-if="zwsjOne">暂无数据</div>
				</div>
				<div class="content_top_left">
					<p>体重 (kg)<el-input v-model="from.weight" @blur="statusChange(from.weight, 2)" @input="valueChange('weight')" :disabled="edit"></el-input></p>
					<div id="echartsCenter" class="echarts"></div>
					<div style="position: absolute; top: 50%; left: 47%" v-if="zwsjTwo">暂无数据</div>
				</div>
				<div class="content_top_left">
					<p>
						睡眠时长 (h)
						<el-select v-model="from.sleep" @change="statusChange(from.sleep, 3)" filterable allow-create default-fi rst-option :disabled="edit" placeholder="请选择睡眠时长">
							<el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label"> </el-option>
						</el-select>
					</p>
					<div id="echartsRight" class="echarts"></div>
					<div style="position: absolute; top: 50%; left: 47%" v-if="zwsjThree">暂无数据</div>
				</div>
			</div>
			<div class="content_bot">
				<div class="bot_left bot_con">
					<h2>今日状态自评</h2>
					<ul>
						<li v-for="(itm, idx) in progressData" :key="idx">
							<el-tooltip class="item" effect="dark" :content="itm.title" placement="top-start">
								<span class="con_name">{{ itm.name }} <i class="el-icon-info"></i></span>
							</el-tooltip>
							<div class="con_progress">
								<span v-for="(item, index) in 10" :key="index" @click="progressClick(item, idx + 1, true, true)">{{ item }}</span>
								<div class="progress_dia" :style="{ width: progressOptions[idx + 1].width, background: progressOptions[idx + 1].color }" @click="diaClick($event, idx + 1)">
									<span class="ye" :style="{ 'border-color': progressOptions[idx + 1].color, color: progressOptions[idx + 1].color }">{{ progressOptions[idx + 1].value }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="bot_right bot_con">
					<h2>
						训练前疼痛自评
						<el-tooltip class="item" effect="dark" content="数字越大,疼痛度越高" placement="right">
							<i class="el-icon-info" style="color: #666"></i>
						</el-tooltip>
					</h2>
					<div class="bot_right_content">
						<div class="body_con">
							<img src="./../../../static/body.png" alt="" />
							<!-- 左上肢选中 -->
							<div class="leftarm" @click="bodyChange('leftarm')">
								<img v-show="leftarm" src="./../../../static/leftarm.png" alt="" />
							</div>

							<!-- 右上肢选中 -->
							<div class="rightarm" @click="bodyChange('rightarm')">
								<img v-show="rightarm" src="./../../../static/rightarm.png" alt="" />
							</div>

							<!-- 左下肢选中 -->
							<div class="leftleg" @click="bodyChange('leftleg')">
								<img v-show="leftleg" src="./../../../static/leftleg.png" alt="" />
							</div>

							<!-- 右下肢选中 -->
							<div class="rightleg" @click="bodyChange('rightleg')">
								<img v-show="rightleg" src="./../../../static/rightleg.png" alt="" />
							</div>

							<!-- 躯干选中 -->
							<div class="bosom" @click="bodyChange('bosom')">
								<img v-show="bosom" src="./../../../static/bosom.png" alt="" />
							</div>
						</div>

						<!-- 左上肢选择框 -->
						<div class="leftarmSelect" v-show="leftarm">
							<span>左上肢</span>
							<el-select v-model="leftarmValue" @change="selectChange(leftarmValue, 1)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 右上肢选择框 -->
						<div class="rightarmSelect" v-show="rightarm">
							<el-select v-model="rightarmValue" @change="selectChange(rightarmValue, 3)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>右上肢</span>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 左下肢选择框 -->
						<div class="leftlegSelect" v-show="leftleg">
							<span>左下肢</span>
							<el-select v-model="leftlegValue" @change="selectChange(leftlegValue, 2)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 右下肢选择框 -->
						<div class="rightlegSelect" v-show="rightleg">
							<el-select v-model="rightlegValue" @change="selectChange(rightlegValue, 4)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>右下肢</span>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 躯干选择框 -->
						<div class="bosomSelect" v-show="bosom">
							<el-select v-model="bosomValue" @change="selectChange(bosomValue, 5)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>躯干</span>
							<img src="./../../../static/line.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import rightarmImg from './../../../static/rightarm.png'
export default {
	data() {
		return {
			zwsjOne: false,
			zwsjTwo: false,
			zwsjThree: false,
			Loading: true,
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			infoData: {},
			value1: new Date(),
			customDateArr: [],
			firstNum: true,
			edit: this.$route.query.edit == '1' ? true : false,
			progressOptions: {
				1: {
					color: '#999999',
					value: '0',
					width: '',
				},
				2: {
					color: '#999999',
					value: '0',
					width: '',
				},
				3: {
					color: '#999999',
					value: '0',
					width: '',
				},
				4: {
					color: '#999999',
					value: '0',
					width: '',
				},
			},
			progressData: [
				{
					name: '睡眠质量',
					title: '数字越大，睡眠越好',
				},
				{
					name: '饮食欲望',
					title: '数字越大，食欲越好',
				},
				{
					name: '自我状态评价',
					title: '数字越大，状态越好',
				},
				{
					name: '肌肉恢复度',
					title: '数字越大，恢复越好',
				},
			],
			options: [],
			from: {},
			value: [],
			echartsData: {},
			leftarm: false,
			leftarmValue: 0,

			rightarm: false,
			rightarmValue: 0,

			leftleg: false,
			leftlegValue: 0,

			rightleg: false,
			rightlegValue: 0,

			bosom: false,
			bosomValue: 0,
		}
	},
	mounted() {
		this.options = this.$utils.sleepOptions()
		this.serveInfo()
		this.create()
		this.getDate()
	},
	computed: {
		customPickerOptions() {
			let that = this
			that.type = true
			return {
				cellClassName(date) {
					if (that.customDateArr.includes(that.getFullDate(date))) {
						return 'custom_date_class'
					}
				},
			}
		},
	},
	methods: {
		selectChange(value, type) {
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/addPain',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
						position: type,
						value: value,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
					}
				})
		},
		bodyChange(name) {
			this[name] = !this[name]
			if (!this[name]) this[name + 'Value'] = 0
		},
		dateChange(date) {
			this.create()
			this.getDate()
		},
		getMonthDate(date) {
			this.type = false
			var nowDate = null
			if (date) {
				nowDate = new Date(date)
			} else {
				nowDate = new Date()
			}
			var fullYear = nowDate.getFullYear()
			var month = nowDate.getMonth() + 1 // getMonth 方法返回 0-11，代表1-12月
			var endOfMonth = new Date(fullYear, month, 0).getDate() // 获取本月最后一天
			var endDate = this.getFullDate(nowDate.setDate(endOfMonth)) //当月最后一天
			var startDate = this.getFullDate(nowDate.setDate(1)) //当月第一天
			this.getDate(startDate, endDate)
		},
		getDate(startDate, endDate) {
			this.$axios
				.post(
					'/p/statusrecord/getDate',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						cate: 1,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.customDateArr = res.data.data
					}
				})
		},
		// 格式化日期格式为 xxxx-xx-xx
		getFullDate(targetDate) {
			var D, y, m, d
			if (targetDate) {
				D = new Date(targetDate)
				y = D.getFullYear()
				m = D.getMonth() + 1
				d = D.getDate()
			} else {
				y = fullYear
				m = month
				d = date
			}
			m = m > 9 ? m : '0' + m
			d = d > 9 ? d : '0' + d
			return y + '-' + m + '-' + d
		},
		serveInfo() {
			this.$axios
				.post(
					'/p/injury/staffInfo',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.infoData = res.data.data[0]
						this.Loading = false
					}
				})
		},
		reBack() {
			this.$router.push({
				path: '/conditionModule/subjectiveState',
				query: {
					type: 1,
				},
			})
		},
		setEcharts() {
			let areaOne = 0,
				areaTwo = 0,
				areaThree = 0,
				numOne = 0,
				numTwo = 0,
				numThree = 0
			if (this.echartsData) {
				// numOne =  this.echartsData[1] && this.echartsData[1].length
				// numTwo = this.echartsData[2] && this.echartsData[2].length
				// numThree = this.echartsData[3] && this.echartsData[3].length
				this.echartsData[1] &&
					this.echartsData[1].forEach(item => {
						if (Number(item.value)) numOne++
						areaOne += Number(item.value)
					})
				if (areaOne == 0) {
					this.zwsjOne = true
				} else {
					this.zwsjOne = false
				}
				this.echartsData[2] &&
					this.echartsData[2].forEach(item => {
						if (Number(item.value)) numTwo++
						areaTwo += Number(item.value)
					})
				if (areaTwo == 0) {
					this.zwsjTwo = true
				} else {
					this.zwsjTwo = false
				}
				this.echartsData[3] &&
					this.echartsData[3].forEach(item => {
						if (Number(item.value)) numThree++
						areaThree += Number(item.value)
					})
				if (areaThree == 0) {
					this.zwsjThree = true
				} else {
					this.zwsjThree = false
				}
			}
			let echartsFirst = this.$echarts.init(document.getElementById('echartsFirst'))
			let option = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						this.echartsData[1] &&
						this.echartsData[1].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: '次/min',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: false,
					},
					axisTick: {
						inside: true,
					},
				},
				color: ['#0055E9'],
				series: [
					{
						type: 'line',
						data:
							this.echartsData[1] &&
							this.echartsData[1].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},
						markLine: {
							//平均线设置
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#0055E9',
									formatter: '全队平均值 : 100',
								},
							},
							data: [
								{
									yAxis: (areaOne / numOne).toFixed(1), //线的值
									name: '平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
					},
				],
			}
			echartsFirst.setOption(option)
			this.$echartsResize(echartsFirst)

			let echartsCenter = this.$echarts.init(document.getElementById('echartsCenter'))
			let optionC = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						this.echartsData[2] &&
						this.echartsData[2].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: 'kg',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: false,
					},
					axisTick: {
						inside: true,
					},
				},
				color: ['#EC8B25'],
				series: [
					{
						type: 'line',
						data:
							this.echartsData[2] &&
							this.echartsData[2].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},
						markLine: {
							//平均线设置
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#EC8B25',
									formatter: '全队平均值 : 100',
								},
							},
							data: [
								{
									yAxis: (areaTwo / numTwo).toFixed(1), //线的值
									name: '平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
					},
				],
			}
			echartsCenter.setOption(optionC)
			this.$echartsResize(echartsCenter)

			let echartsRight = this.$echarts.init(document.getElementById('echartsRight'))
			let optionR = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '3%',
					top: '15%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						this.echartsData[3] &&
						this.echartsData[3].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
						textStyle: {
							align: 'left', //**
						},
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: 'h',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: false,
					},
					axisTick: {
						inside: true,
					},
				},
				color: ['#269A2E'],
				series: [
					{
						type: 'line',
						data:
							this.echartsData[3] &&
							this.echartsData[3].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},
						markLine: {
							//平均线设置
							silent: true, //true 去掉鼠标悬浮该线上的动画
							symbol: 'none', //该线无样式
							label: {
								width: '1',
								show: true, //该线上的值去掉,
								position: 'insideEndTop',
							},
							lineStyle: {
								//设置该线样式
								normal: {
									width: '1',
									type: 'dashed',
									color: '#269A2E',
									formatter: '全队平均值 : 100',
								},
							},
							data: [
								{
									yAxis: (areaThree / numThree).toFixed(1), //线的值
									name: '平均值',
									label: {
										show: true,
										formatter: '{b}: {c}',
									},
								},
							],
						},
					},
				],
			}
			echartsRight.setOption(optionR)
			this.$echartsResize(echartsRight)
		},

		create() {
			// for(var i=1; i<5; i++) {
			//   this.progressClick(0, i)
			// }
			this.$set(this.from, 'morning', '')
			this.$set(this.from, 'weight', '')
			this.$set(this.from, 'sleep', '')
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/read',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.echartsData = res.data.data.echarts
						var oneType = true,
							twoType = true,
							threeType = true,
							fourType = true
						res.data.data.status.forEach(item => {
							if (item.type == 1) {
								this.$set(this.from, 'morning', item.value)
							} else if (item.type == 2) {
								this.$set(this.from, 'weight', item.value)
							} else if (item.type == 3) {
								this.$set(this.from, 'sleep', item.value)
							} else if (item.type == 8) {
								oneType = false
								this.progressClick(item.value, 1)
							} else if (item.type == 9) {
								twoType = false
								this.progressClick(item.value, 2)
							} else if (item.type == 10) {
								threeType = false
								this.progressClick(item.value, 3)
							} else if (item.type == 11) {
								fourType = false
								this.progressClick(item.value, 4)
							}
						})

						if (oneType) {
							this.progressClick(0, 1)
						}
						if (twoType) {
							this.progressClick(0, 2)
						}
						if (threeType) {
							this.progressClick(0, 3)
						}
						if (fourType) {
							this.progressClick(0, 4)
						}

						this.leftarm = false
						this.leftarmValue = 0

						this.rightarm = false
						this.rightarmValue = 0

						this.leftleg = false
						this.leftlegValue = 0

						this.rightleg = false
						this.rightlegValue = 0

						this.bosom = false
						this.bosomValue = 0
						res.data.data.pain.length &&
							res.data.data.pain.forEach(item => {
								if (item.position == 1) {
									this.leftarm = true
									this.leftarmValue = Number(item.value)
								} else if (item.position == 2) {
									this.leftleg = true
									this.leftlegValue = Number(item.value)
								} else if (item.position == 3) {
									this.rightarm = true
									this.rightarmValue = Number(item.value)
								} else if (item.position == 4) {
									this.rightleg = true
									this.rightlegValue = Number(item.value)
								} else if (item.position == 5) {
									this.bosom = true
									this.bosomValue = Number(item.value)
								}
							})

						this.setEcharts()
					}
				})
		},
		statusChange(value, index) {
			let type = 0
			switch (index) {
				case 1:
					if (value < 0) type = 1
					else if (value > 150) type = 2
					break
				case 2:
					if (value < 0) type = 1
					else if (value > 200) type = 2
					break
			}
			if (type == 1) {
				return this.$message({
					message: '您输入的结果值可能太小哦！',
					type: 'error',
				})
			} else if (type == 2) {
				return this.$message({
					message: '您输入的结果值可能太大哦！',
					type: 'error',
				})
			}

			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/add',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
						type: index,
						value: value ? value : 0,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.$message({
							type: 'success',
							message: res.data.message,
						})
						this.create()
					} else {
						this.$message({
							type: 'error',
							message: res.data.message,
						})
					}
				})
		},
		progressClick(index, rows, type, editType) {
			if (this.edit && editType) {
				this.$message({
					message: '暂无权限',
					type: 'warning',
				})
				return false
			}
			if (type) {
				let etype = 0
				switch (rows) {
					case 1:
						etype = 8
						break
					case 2:
						etype = 9
						break
					case 3:
						etype = 10
						break
					case 4:
						etype = 11
						break
				}
				this.statusChange(index, etype)
			}
			if (index > 0 && index <= 3) {
				this.progressOptions[rows] = {
					value: index,
					color: '#81BDFF',
					width: 10 * index + '%',
				}
			} else if (index >= 4 && index <= 7) {
				this.progressOptions[rows] = {
					value: index,
					color: '#EC8B25',
					width: 10 * index + '%',
				}
			} else if (index >= 8 && index <= 10) {
				this.progressOptions[rows] = {
					value: index,
					color: '#FF8181',
					width: 10 * index + '%',
				}
			} else if (index == 0) {
				this.progressOptions[rows] = {
					value: index,
					color: '#999999',
					width: index + '%',
				}
			}
		},
		valueChange(name) {
			let value = this.from[name]
			var regExp = /^[1-9][0-9]*[.]?([0-9]+)?$/
			if (!regExp.test(value)) {
				this.from[name] = ''
				return false
			}
		},
		diaClick(e, index) {
			if (e.target.className !== 'progress_dia') {
				return false
			}
			let clientWidth = e.target.clientWidth
			let layerX = e.layerX
			let a = this.progressOptions[index].width.slice(0, -1)
			let clickX = clientWidth / a
			let num = Math.round(layerX / clickX)
			let etype = 0
			switch (index) {
				case 1:
					etype = 8
					break
				case 2:
					etype = 9
					break
				case 3:
					etype = 10
					break
				case 4:
					etype = 11
					break
			}
			if (num < 5) {
				this.progressOptions[index] = {
					width: 0,
					color: '#999',
					value: 0,
				}
				this.statusChange(0, etype)
			} else if (num >= 5 && num <= 10) {
				this.progressOptions[index] = {
					width: '10%',
					color: '#81BDFF',
					value: 1,
				}
				this.statusChange(1, etype)
			} else if (num >= 11 && num <= 20) {
				this.progressOptions[index] = {
					width: '20%',
					color: '#81BDFF',
					value: 2,
				}
				this.statusChange(2, etype)
			} else if (num >= 21 && num <= 30) {
				this.progressOptions[index] = {
					width: '30%',
					color: '#81BDFF',
					value: 3,
				}
				this.statusChange(3, etype)
			} else if (num >= 31 && num <= 40) {
				this.progressOptions[index] = {
					width: '40%',
					color: '#EC8B25',
					value: 4,
				}
				this.statusChange(4, etype)
			} else if (num >= 41 && num <= 50) {
				this.progressOptions[index] = {
					width: '50%',
					color: '#EC8B25',
					value: 5,
				}
				this.statusChange(5, etype)
			} else if (num >= 51 && num <= 60) {
				this.progressOptions[index] = {
					width: '60%',
					color: '#EC8B25',
					value: 6,
				}
				this.statusChange(6, etype)
			} else if (num >= 61 && num <= 70) {
				this.progressOptions[index] = {
					width: '70%',
					color: '#EC8B25',
					value: 7,
				}
				this.statusChange(7, etype)
			} else if (num >= 71 && num <= 80) {
				this.progressOptions[index] = {
					width: '80%',
					color: '#FF8181',
					value: 8,
				}
				this.statusChange(8, index)
			} else if (num >= 81 && num <= 90) {
				this.progressOptions[index] = {
					width: '90%',
					color: '#FF9181',
					value: 9,
				}
				this.statusChange(9, etype)
			} else if (num >= 91 && num <= 100) {
				this.progressOptions[index] = {
					width: '100%',
					color: '#FF9181',
					value: 10,
				}
				this.statusChange(10, etype)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.subjective_view {
	.interMant_top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		background: #fff;
		margin-bottom: 10px;
		box-sizing: border-box;
		border-radius: 5px;
		.interTop_left {
			width: 70%;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333;
			.el-avatar {
				width: 60px;
				height: 60px;
				img {
					width: 100%;
				}
			}
			span {
				margin-left: 5px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
		.interTop_right {
			> .el-date-editor {
				margin-right: 20px;
				border-radius: 0;
				border-right-width: 0;
				border: none;
				margin-right: 60px;
				/deep/ .el-input__inner {
					border: none;
					border-radius: 0;
					border-bottom: 2px solid #ccc;
					&:focus {
						border-color: #0055e9;
					}
				}
				&:focus {
					border-color: #0055e9;
				}
			}
			> span {
				font-size: 16px;
				margin-right: 20px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
.subjective_content {
	background: #fff;
	margin-top: 15px;
	border-radius: 5px;
	padding: 20px 10px;
	.content_top {
		display: flex;
		box-sizing: border-box;
		justify-content: space-around;
		.content_top_left {
			position: relative;
			width: 32%;
			border-radius: 5px;
			p {
				padding: 8px 20px;
				font-size: 16px;
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				.el-input {
					flex: 1;
					margin-left: 15px;
					border-radius: 15px;
				}
				.el-select {
					flex: 1;
					margin-left: 15px;
				}
			}
			.echarts {
				width: 100%;
				height: 300px;
			}
			&:nth-child(1) {
				border: 1px solid #0055e9;
				p {
					background: rgba(0, 85, 233, 0.06);
				}
			}
			&:nth-child(2) {
				border: 1px solid #e97800;
				p {
					background: rgba(192, 100, 2, 0.06);
				}
			}
			&:nth-child(3) {
				border: 1px solid #24c330;
				p {
					background: rgba(39, 156, 48, 0.09);
				}
			}
		}
	}
	.content_bot {
		display: flex;
		margin-top: 30px;
		justify-content: space-between;
		padding: 0 8px;
		.bot_con {
			width: 49%;
			height: 400px;
			border-radius: 5px;
			border: 1px solid #cccccc;
		}
		.bot_left {
			h2 {
				text-align: center;
				margin: 30px 0;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
			}
			ul {
				width: 92%;
				margin-left: 3%;
				margin-top: 70px;
				li {
					display: flex;
					margin-bottom: 30px;
					.con_name {
						width: 120px;
						display: inline-block;
						font-size: 16px;
						font-weight: 500;
						margin-right: 20px;
						color: #666;
						i {
							color: #666;
							font-size: 18px;
						}
					}
					.con_progress {
						flex: 1;
						height: 100%;
						margin-top: 5px;
						position: relative;
						> span {
							display: inline-block;
							width: 9%;
							background: #f1f1f1;
							border-radius: 10px;
							margin-right: 1%;
							color: #f1f1f1;
							z-index: 9;
							&:hover {
								cursor: pointer;
							}
						}
						.progress_dia {
							position: absolute;
							top: 0;
							left: 0;
							width: 0px;
							height: 100%;
							border-radius: 10px;
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
							transition: width 1s;
							z-index: 8;
							&:hover {
								cursor: pointer;
							}
							> span {
								position: absolute;
								top: -8px;
								right: -29px;
								display: block;
								width: 25px;
								height: 25px;
								border: 4px solid #999999;
								color: #999999;
								text-align: center;
								line-height: 25px;
								border-radius: 50%;
								z-index: 10;
							}
						}
					}
				}
			}
		}
		.bot_right {
			h2 {
				text-align: center;
				margin: 30px 0;
				font-size: 20px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
			}
			.bot_right_content {
				height: 290px;
				position: relative;
				.body_con {
					width: 93px;
					height: 100%;
					margin: 0 auto;
					position: relative;
					img {
						height: 100%;
					}
					.leftarm {
						position: absolute;
						width: 22px;
						height: 120px;
						right: 2px;
						top: 48.5px;
						z-index: 2;
						img {
							width: 100%;
							height: 100%;
						}
						&:hover {
							cursor: pointer;
						}
					}

					.rightarm {
						position: absolute;
						width: 23px;
						height: 120px;
						left: 0px;
						top: 48.5px;
						z-index: 2;
						img {
							width: 100%;
							height: 100%;
						}
						&:hover {
							cursor: pointer;
						}
					}

					.leftleg {
						position: absolute;
						width: 28.5px;
						height: 145.5px;
						right: 19px;
						bottom: 0px;
						img {
							width: 100%;
							height: 100%;
						}
						&:hover {
							cursor: pointer;
						}
					}

					.rightleg {
						position: absolute;
						width: 28.5px;
						height: 145.5px;
						left: 17px;
						bottom: 0px;
						img {
							width: 100%;
							height: 100%;
						}
						&:hover {
							cursor: pointer;
						}
					}

					.bosom {
						position: absolute;
						width: 66px;
						height: 130px;
						left: 13px;
						top: 36px;
						z-index: 1;
						img {
							width: 100%;
							height: 100%;
						}
						&:hover {
							cursor: pointer;
						}
					}
				}
				.leftarmSelect {
					position: absolute;
					right: 20px;
					top: 65px;
					span {
						margin-right: 15px;
						font-weight: 500;
						color: #1f2633;
						font-size: 17px;
					}
					img {
						width: 130px;
						position: absolute;
						left: -140px;
						top: 5px;
					}
				}
				.rightarmSelect {
					position: absolute;
					left: 20px;
					top: 115px;
					span {
						margin-left: 15px;
						font-weight: 500;
						color: #1f2633;
						font-size: 17px;
					}
					img {
						transform: rotateY(180deg);
						width: 130px;
						position: absolute;
						right: -135px;
						top: 5px;
					}
				}
				.leftlegSelect {
					position: absolute;
					right: 20px;
					bottom: 50px;
					span {
						margin-right: 15px;
						font-weight: 500;
						color: #1f2633;
						font-size: 17px;
					}
					img {
						width: 150px;
						position: absolute;
						left: -155px;
						top: 5px;
					}
				}
				.rightlegSelect {
					position: absolute;
					left: 20px;
					bottom: 30px;
					span {
						margin-left: 15px;
						font-weight: 500;
						color: #1f2633;
						font-size: 17px;
					}
					img {
						transform: rotateY(180deg);
						width: 145px;
						position: absolute;
						right: -151px;
						top: 5px;
					}
				}
				.bosomSelect {
					position: absolute;
					left: 20px;
					top: 40px;
					span {
						margin-left: 15px;
						font-weight: 500;
						color: #1f2633;
						font-size: 17px;
					}
					img {
						transform: rotateY(180deg);
						width: 160px;
						position: absolute;
						right: -170px;
						top: 3px;
					}
				}
				.el-select {
					width: 120px;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.custom_date_class {
	span {
		background: #ea6151;
		color: red;
		border-radius: 50%;
		color: #fff !important;
		&:hover {
			background-color: #409eff;
		}
	}
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		width: 100%;
		font-size: 12px;
		color: red;
		bottom: -15px;
		left: 0;
		text-align: center;
		white-space: nowrap;
	}
}
.otim {
	width: 90%;
	overflow: hidden; //超出的文本隐藏
	text-overflow: ellipsis; //溢出用省略号显示
	white-space: nowrap; //溢出不换行
}
.ye {
	background-color: #fff;
}
</style>
